import React from "react";
import theme from "theme";
import { Theme, Image, Text, Link, Box, Section, Em, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ana Sayfa | Fesulkin Spor Merkezi
			</title>
			<meta name={"description"} content={"Fesulkin'a Hoş Geldiniz - Spor, Eğlence ve Harika Zamanlar İçin Gitmeniz Gereken Yer!"} />
			<meta property={"og:title"} content={"Ana Sayfa | Fesulkin Spor Merkezi"} />
			<meta property={"og:description"} content={"Fesulkin'a Hoş Geldiniz - Spor, Eğlence ve Harika Zamanlar İçin Gitmeniz Gereken Yer!"} />
			<meta property={"og:image"} content={"https://fesulkin.com/img/220221_PeterRentschler_PR4_4570-2-1024x682.jpg"} />
			<link rel={"shortcut icon"} href={"https://fesulkin.com/img/3285412.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fesulkin.com/img/3285412.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fesulkin.com/img/3285412.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fesulkin.com/img/3285412.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fesulkin.com/img/3285412.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fesulkin.com/img/3285412.png"} />
			<meta name={"msapplication-TileColor"} content={"https://fesulkin.com/img/3285412.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" background="--color-lightD1" quarkly-title="Hero-13">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Image
					src="https://fesulkin.com/img/220221_PeterRentschler_PR4_4570-2-1024x682.jpg"
					display="block"
					width="40%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="flex-end"
					width="60%"
					padding="80px 80px 80px 80px"
					lg-width="100%"
					md-padding="50px 50px 80px 50px"
					sm-padding="50px 25px 80px 25px"
				>
					<Text
						margin="0px 0px 40px 0px"
						font="normal 500 72px/1.2 --fontFamily-serifGeorgia"
						color="#061e11"
						lg-margin="0px 0px 35px 0px"
						md-font="normal 500 52px/1.2 --fontFamily-serifGeorgia"
						sm-font="normal 500 42px/1.2 --fontFamily-serifGeorgia"
					>
						Fesulkin'a Hoş Geldiniz
					</Text>
					<Text
						margin="0px 0px 40px 0px"
						font="--base"
						color="--greyD3"
						md-text-align="center"
						md-margin="0px 0px 20px 0px"
					>
						Hareketin kalbinde yer alan Fesulkin, sadece tipik bir spor barı değildir - oyuna olan sevginin harika arkadaşlığın neşesiyle buluştuğu canlı bir merkezdir. Her gecenin kahkaha, tezahürat ve dostça rekabetin heyecanıyla dolu olduğu türünün tek örneği bir deneyim için bize katılın.
					</Text>
					<Link href="/contact-us" color="--darkL1" font="normal 600 28px/1.2 --fontFamily-serifGeorgia">
						Masa ayırtın &gt;&gt;
					</Link>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="40%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Fesulkin'ı Neden Tercih Etmelisiniz?
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Aylık Mini Olimpiyatlarımız için hazırlanın! Mini oyunlar, kahkahalar ve bir tutam dostça rekabetten oluşan bir karışım. Televizyondaki profesyonelleri unutun - parlama ve Fesulkin Mini Olimpiyatçısı unvanını alma zamanı!
				</Text>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="60%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						1
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Eğlenceli Oyunlar
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Hızlı bira pong maçlarından keyifli shuffleboard oyunlarına kadar, eğlenceli zamanların asla bitmeyeceği çeşitli etkinlikler sunuyoruz.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						2
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Stil Sahibi Rahatlama
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Dikkatle seçilmiş özel biralarımız ve lezzetli atıştırmalıklarımız, her ziyareti hem lezzet hem de dostluk dolu bir kutlamaya çeviriyor.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						3
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Samimi Bağlantılar
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							İster sık gelen bir dost ister ilk kez gelen bir misafir olun, cana yakın personelimiz ve sıcak ortamımız, sohbet etmeyi ve yeni dostluklar kurmayı son derece kolaylaştırıyor.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="url() 0 0 no-repeat" quarkly-title="About-11">
			<Override
				slot="SectionContent"
				flex-direction="row"
				md-flex-wrap="wrap"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				width="20%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				padding="0px 0px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 30px 0px"
				lg-width="30%"
			>
				<Image
					src="https://fesulkin.com/img/naim-suleymanoglu.jpg"
					md-margin="0px 0px 20px 0px"
					width="200px"
					height="200px"
					object-fit="cover"
					border-radius="50%"
					lg-width="200px"
				/>
			</Box>
			<Box
				width="80%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				align-items="flex-start"
				padding="0px 128px 0px 128px"
				lg-padding="0px 64px 0px 64px"
				lg-width="70%"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="32px 0px 18px 0px" font="--headline2" md-margin="24px 0px 30px 0px" sm-font="--headline3">
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						color="--indigo"
					>
						"
					</Em>
					<Span color="--dark">
						Başarının sırrı, hedefine odaklanmaktır.
					</Span>
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						color="--indigo"
					>
						"
					</Em>
				</Text>
				<Text margin="0px 0px 38px 0px" color="--darkL2" font="--lead">
					<Em>
						Naim Süleymanoğlu, efsanevi bir Türk halterci
					</Em>
				</Text>
			</Box>
		</Section>
		<Components.Images />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});